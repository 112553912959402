.experience-container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* background:
     url("../../assets/peaks.svg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.experience-content{
    /* background:
    linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ); */
    border-radius: 15px;
}