.projects-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
}
.project-description {
  text-align: center;
  max-width: 80%;
  overflow-wrap: break-word;
}
