html {
    scroll-behavior: smooth;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .hero-container__title {
    animation: fadeIn 2s ease-in-out;
  }
.hero-icon{
  height: 50px;
}
.hero-container {
    flex-grow: 1;
    height: 100vh;
    background:
    linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../../assets/back2.avif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
.hero-container__text__container{
    text-align: center;
    padding-top: 40vh;
}

.hero-container__nav-top-left{
    color: white;
    position: absolute;
    top: 2rem;
    left: 2rem;
    text-decoration: none;
}
.hero-container__nav-top-left a {
    font-weight: bold;
    text-decoration: none;
    color: white;
}
.hero-container__nav-top-right a {
    font-weight: bold;
    text-decoration: none;
    color: white;
}
.hero-container__nav-bottom-left a {
    font-weight: bold;
    text-decoration: none;
    color: white;
}
.hero-container__nav-bottom-right a {
    font-weight: bold;
    text-decoration: none;
    color: white;
}
.hero-container__nav-top-left a:hover {
    color: #03DAC5;
    
}
.hero-container__nav-top-right a:hover {
    color: #03DAC5;
}
.hero-container__nav-bottom-left a:hover {
    color: #03DAC5;
}
.hero-container__nav-bottom-right a:hover {
    color: #03DAC5;
}
.hero-container__nav-top-right{
    color: white;
    position: absolute;
    top: 2rem;
    right: 2rem;
    text-decoration: none;
}

.hero-container__nav-bottom-left{
    color: white;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    text-decoration: none;
}
.hero-container__nav-bottom-right{
    color: white;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    text-decoration: none;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.wave-effect
{
  background-image: linear-gradient(
    -225deg,
    #03DAC5 0%,
    #E966A0 50%,
    #BB86FC 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2.5s linear infinite;
}
