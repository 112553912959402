.contact-container{
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.contact-container__form{
    display: flex;
    justify-content: flex-end;
}
#contact-section {
  background:
    url("../../assets/steps.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}
.emailLink{
    padding-top: 25px;
    text-decoration: none;
    color: white;
    font-weight: bold;

}
.emailLink:hover{
  color: #03DAC5;
}
.icon{
  height: 6.5rem;
}
@media (max-width: 600px) {
    .form-container {
      order: 2;
    }
  }
  