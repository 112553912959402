.about-container{
    display: flex;
    align-items: center;
    min-height: 100vh;
}

#about-me-section {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

  .about-container__text__title{
    padding-bottom: 1.5rem;
  }
  .about-image {
    width: 100%;
  }
  @media (max-width: 900px) {
    .about-image {
      width: 50%;
    }
  }